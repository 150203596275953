<template>
  <div class="page">
    <img src="../../assets/images/qianmi/banner.png" class="banner">
    <div class="page-content">
      <div class="search-container">
        <div class="search-box">
          <i class="search" />
          <input v-model="code" type="text" placeholder="请输入手机号或扫描MEI码查询">
          <i class="scan" />
        </div>
        <div class="search-btn" @click="search">点击查询</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      code: ''
    }
  },
  methods: {
    search() {
      if (!this.code.trim()) {
        this.$toast('请输入手机号或扫描MEI码查询')
        return
      }
      this.$router.push({
        path: "/QianmiList",
        query: {
          code: this.code.trim()
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.page{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner{
  width: 100%;
  height: 360px;
}
.page-content{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #FFFAEC 74%, #F0C246 100%);
}
.search-container{
  width: 730px;
  height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url(../../assets/images/qianmi/bg_sr.png);
  background-size: 100% 100%;
  padding-bottom: 101px;
}
.search-box{
  width: 610px;
  height: 90px;
  background: #F4F4F4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  i {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    &.search{
      background-image: url('../../assets/images/qianmi/icon_ss.png');
      background-size: 100% 100%;
    }
    &.scan{
      background-image: url('../../assets/images/qianmi/icon_sm.png');
      background-size: 100% 100%;
    }
  }
  input {
    width: 100%;
    background: inherit;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    padding: 0 10px;
  }

}
.search-btn{
  width: 640px;
  height: 140px;
  background-image: url('../../assets/images/qianmi/btn_djcx.png');
  background-size: 100% 100%;
  text-align: center;
  padding-top: 40px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 49px;
}
</style>